export const firebaseConfig = {
  apiKey: "AIzaSyC0yHwTnFoBEGxiAn9yfGV1cQKGgsHI54k",
  authDomain: "myconreg-c65d2070-dev.firebaseapp.com",
  projectId: "myconreg-c65d2070-dev",
  storageBucket: "myconreg-c65d2070-dev.appspot.com",
  messagingSenderId: "736350385640",
  appId: "1:736350385640:web:6bad7490f1f551bc10605d",
  measurementId: "G-2TRJVNXX5P"
};
export const SQUARE_IS_SANDBOX = true;
export const SQUARE_CLIENT_ID = 'sandbox-sq0idb-Z-wabM6SFwe2gjU6fU9WMw';
export const SQUARE_APP_ID = 'sandbox-sq0idb-Z-wabM6SFwe2gjU6fU9WMw';